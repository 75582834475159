<template>
  <div ref="echartMap" style="width:100%;height:100%;">
  </div>
</template>
<script>
import echarts from 'echarts'
import 'echarts/map/js/china.js';
export default {
  name:'echartMap',
  props: {
    echartMapData:{
      type:Array,
      default:[]
    },
    
  },
  data(){
    return {
    }
  },
  mounted(){
    this.getEchart();
  },
  watch:{
    echartMapData(n,o){
      this.$nextTick(()=> {
        this.getEchart();
      });
    },
  },
  methods:{
    getEchart(){
        let myChart =  echarts.init(this.$refs.echartMap)
        let option = {  
                backgroundColor: '#FFFFFF',  
                tooltip : {  
                  trigger: 'item'  
                },  
                //左侧小导航图标
                visualMap: { 
                  min: 0,
                  max:1000,
                  left: 'left',
                  top: 'top',
                  text: ['高', '低'],
                  calculable: false,
                  show: true,
                  inRange: {
                      color: ['#e0ffff', '#006edd']
                  } 
                },  
                color: ['#4FA8F9', '#F5A623'],
                
                //配置属性
                series: [{
                  name: '访客人数',
                  type: 'map',
                  mapType: 'china',
                  itemStyle: {
                      normal: {
                          label: {
                              show: false
                          }
                      },
                      emphasis: {
                          label: {
                              show: true
                          }
                      }
                  },
                    data:this.echartMapData  //数据
                }]  
            }; 
        myChart.setOption(option);
    }
  }
}
</script>