<template>
  <div ref="echartBar" style="width:100%;height:100%;">
  </div>
</template>
<script>
import echarts from 'echarts'
export default {
  name:'echartBar',
  props: {
    echartBarData:{
      type:Array,
      default:[]
    },
    
  },
  data(){
    return {
    }
  },
  mounted(){
    this.getEchart();
  },
  watch:{
    echartBarData(n,o){
      this.$nextTick(()=> {
        this.getEchart();
      });
    },
  },
  methods:{
    getEchart(){
        let dataList ={
          x:[],
          y:[]
        }
        this.echartBarData.forEach(t=>{
          dataList.x.push(t.Depth)
          dataList.y.push(t.Count)
        })
        let myChart =  echarts.init(this.$refs.echartBar)
        let option = {
            legend: {
              data:['访问次数']
            },
            tooltip: {
                trigger: 'axis',
            },
            grid: {
                left: '1%',
                top:'10%',
                right: '3%',
                bottom: '4%',
                containLabel: true
            },
            xAxis :
            {
              type : 'category',
              data : dataList.x,
              axisTick: {
                  alignWithLabel: true
              }
            },
            yAxis: {
              axisLine:{
                show:false
              },
              axisTick:{
                show:false
              },
              type: 'value',
              splitLine: {
                  show: true,
                  lineStyle:{
                      type:'dashed'
                  }
              }
            },
            series:{   
                name:'访问次数',
                type:'bar',
                itemStyle: {
                  normal: {
                      color: '#409EFF',
                      lineStyle: {
                          color: '#409EFF'
                      }
                  }
                },
                stack: '总量',
                smooth: true,
                data:dataList.y
            }
        };
        myChart.setOption(option);
    }
  }
}
</script>