<template>
  <div class="main-container">
    <div class="top">
       <span  >统计时间：</span>

       <el-select style="margin:0 10px;width:110px;" v-model="timeValue" placeholder="请选择" @change='changeTimeOption'>
        <el-option
          v-for="item in timeOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>

      <div >
        <span>{{sevenStartTime}}</span>
        <span  style="margin:0 5px">至</span>
        <span>{{sevenEndTime}}</span>
      </div>

    </div>

    <div class="area">
      <div class="title">访问深度
        <el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" placement="top-start">
          <div v-html='toBreak(tooltip.deep)' slot='content'></div>
          <i class="el-icon-warning icon"></i>
        </el-tooltip>
      </div>
      <div class="echart-container">
        <div class="echart-item">
          <echart-bar :echartBarData='echartBarData' ></echart-bar>
        </div>
        <div class="echart-item">
          <echart-pie :echartPieData='echartPieDataDeep' ></echart-pie>
        </div>
      </div>
    </div>

    <div class="area">
      <div class="title">访客地域分布
        <el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.area" placement="top-start">
          <i class="el-icon-warning icon"></i>
        </el-tooltip>
      </div>
      <div class="echart-container">
        <div class="echart-item">
          <echart-map :echartMapData='echartMapData' ></echart-map>
        </div>
        <div class="echart-item">
          <echart-pie :echartPieData='echartPieDataArea' ></echart-pie>
        </div>
      </div>
      <div class="table">
        <el-table :data="tableList">
          <el-table-column label="序号">
            <template slot-scope="scope">
              {{scope.$index+1}}
            </template>
          </el-table-column>
          <el-table-column label="地区" prop="Province" >
            <template slot-scope="scope">
              {{scope.row.Province==''?'其它':scope.row.Province}}
            </template>
          </el-table-column>
          <el-table-column label="浏览量" prop="PV" sortable></el-table-column>
          <el-table-column label="访客数" prop="UV"></el-table-column>
          <el-table-column label="商品浏览量" prop="ProductPV"></el-table-column>
          <el-table-column label="商品访客数" prop="ProductUV"></el-table-column>
        </el-table>

        <el-pagination
          style="margin-top:20px;float:right;"
          v-if="page.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page.current"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="page.size"
          :total="page.total"
          layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import echartBar from './echartBar'
import echartPie from './echartPie'
import echartMap from './echartMap'
import apiList from '@/api/other'
export default {
  components:{echartBar, echartPie,echartMap},
  data(){
    return {
      tooltip:{
        deep:'横轴（页面深度）：统计筛选时间内，用户单次连续访问的商城页面数，以用户单次访问最深的页面数为准；\n纵轴（访问次数）：统计各页面深度的访问次数；1人访问多次N页计多次',
        area:'统计筛选时间内，根据访客定位或访问时候的ip地址进行计算，如果一个访客在筛选时间内通过多个不同省份访问，会同时计入多个省份；1人在同一省份访问多次，计1人'
      },
      echartPieDataDeep:[],
      echartBarData:[],
      echartMapData: [],
      echartPieDataArea:[],
      timeValue:4,
      timeOptions:[
        {
          value:4,
          label:'近七天'
        },
        {
          value:5,
          label:'近30天'
        }
      ],
      TimeType:4,
      sevenStartTime:'',
      sevenEndTime:'',
      tableList:[
        {
          Area:'浙江'
        }
      ],
      page:{
        size:20,
        total:0,
        current:1
      }
    }
  },
  created(){
    this.getRecentDays(7)
    this.getDataDepth()
    this.getDataAnalyze()
  },
  methods:{
    toBreak(val) {
      return val.split('\n').join('<br>')
    },
    async getDataDepth(){
      try{
        let result =  await apiList.visitorVisitorDepth(
          {
            TimeType:this.timeValue
          }
        )
        this.echartBarData = result.Result
        // echartPieDataDeep
        this.echartPieDataDeep = []
        result.Result.forEach(t=>{
          let item = {
            name:t.Depth,
            value:t.Count
          }
          this.echartPieDataDeep.push(item)
        })
      }catch(e){

      }
    },
    async getDataAnalyze(){
      try{
        let result =  await apiList.visitorVisitorAreaAnalyze(
          {
            TimeType : this.timeValue,
            Skip : this.page.size*(this.page.current-1),
            Take : this.page.size,
          }
        )
        this.tableList = result.Result.Results
        this.echartMapData = []
        result.Result.Results.forEach(t=>{
          let item = {
            name:t.Province,
            value:t.UV
          }
          this.echartMapData.push(item)
        })
        this.echartPieDataArea = this.echartMapData
      }catch(e){

      }
    },
    
    //获取近几天
    getRecentDays(days){
      let time =  new Date()
      let lastDay = new Date(time - 3600 * 24 * 1000)
      let firstDay = new Date(time - 3600 * 24 * 1000 * days)
      this.sevenEndTime =  this.formatDate(lastDay)
      this.sevenStartTime = this.formatDate(firstDay)
    },
    formatTen(num) {
      return num > 9 ? (num + "") : ("0" + num);
    },
    //格式化时间 YYYY-MM-DD/ YYYY-MM-DD hh:mm:ss
    formatDate(date) {
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var h = date.getHours();
      //获取当前分钟数(0-59)
      var m = date.getMinutes();
      //获取当前秒数(0-59)
      var s = date.getSeconds();
      return year + "-" + this.formatTen(month) + "-" + this.formatTen(day);
    },
    //改变时间段
    changeTimeOption(e){
      if(e==4){
        //近七天
        this.getRecentDays(7)
      }else if(e==5){
        //近30天
        this.getRecentDays(30)
      }
        this.getDataDepth()
        this.getDataAnalyze()
    },
    handleSizeChange(val){
      this.page.size = val
      this.getDataAnalyze()
    },
    handleCurrentChange(val){
      this.page.current = val
      this.getDataAnalyze()

    }
  }
}
</script>
<style lang="less" scoped>
.main-container{
  .icon{
    font-size: 16px;
    color:#999;
    margin-left:10px;
    cursor: pointer;
  }
  .title{
    font-size: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    position: relative;
    .right{
      float: right;
      margin-right: 5px;
    }
  }
  .top{
    padding: 10px 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #fff;
  }
  .area{
    background-color: #fff;
    margin-top:10px;
    padding: 20px;
    .echart-container{
      height: 400px;
      display: flex;
      flex-direction: row;
      .echart-item{
        width: 50%;
        height: 100%
      }
    }
    .table{
      margin-top:20px;
      overflow: hidden;
    }
  }
}
</style>